import React from "react"

class TimelineDiagram extends React.Component {
  state = {
    items: [
      {
        date: "2009",
        copy:
          "Established MacCormick Inc. as an expert advisory practice in Social Impact Measurement and Management, advising corporates, governments, financial institutions and NGO clientele.",
      },
      {
        date: "2010",
        copy:
          "Created the World’s First Social Performance Index (MSPI). Evaluating TSX listed Mining and Metals companies. At the time, less than 2% of TSX companies qualified for the index criteria.",
      },
      {
        date: "2011 - 2015",
        copy:
          "The firm expanded its consulting operations into South America (Argentina, Peru and Colombia), Africa (Burkina, Mali and South Africa) and Europe - completing 35 project across 8 countries.",
      },
      {
        date: "2012",
        copy:
          "Published the World’s First Social Performance Index (2011) with launch sponsorship from Scotiabank and Fasken Martineau LLP.",
      },
      {
        date: "2013",
        copy:
          "Published the 2nd Edition of the World’s First Social Performance Index (2012).",
      },
      {
        date: "2014 - 2015",
        copy: "The consulting practice opens an office in Lima, Peru.",
      },
      {
        date: "2016",
        copy:
          "The S Factor Co., a subsidiary of MacCormick is launched, automating the MSPI and transforming the company into a data and analytics provider. Taking ESG data 100-layers deeper on social factors.",
      },
      {
        date: "2016 ",
        copy:
          "Sir Mark Moody-Stuart, (Ex-Chairman Royal Dutch Shell, Chairman United Nations Global Compact, Board Member Saudi Aramco, Hermes Investments, FTSE), endorsed the MSPI as “the most comprehensive, systematic ESG rating system the sector has ever seen.”",
      },
      {
        date: "2017",
        copy:
          "S-Factor partners with S&P Market Intelligence for financial data and analytics",
      },
      {
        date: "2017",
        copy:
          "Thomson Reuters / Lipper promote the MSPI through the Reuters Financial Risk summit.",
      },
      {
        date: "2017",
        copy:
          "S-Factor was approved as a member of the Social Stock Exchange, now the Impact Investment Network following the ratification of its impact report, UK.",
      },
      {
        date: "2017",
        copy:
          "S-Factor prototypes mining analytics with clientele and soft launch.",
      },
      {
        date: "2018",
        copy:
          "The S Factor Co. scales data & analytics coverage to total universal public coverage.",
      },
      {
        date: "2018",
        copy:
          "S-Factor files IP in Canada and the US for strategic protection through growth in the sector. ",
      },
      {
        date: "2019",
        copy:
          "The S Factor Co. closes SEED round of funding, further enhances product function and features, begins client trials and grows the data team.",
      },
      {
        date: "2020",
        copy:
          "The S Factor Co. launches its COVID-19 Pandemic Dataset, monitoring how companies are responding to the crisis. The pandemic pushes ESG, and more specifically the “S,” or social data to the forefront of market considerations.",
      },
      {
        date: "2020",
        copy:
          "The S-Factor product is approved for its compliance with the Sustainability Accounting Standards Board (SASB) and is authorized to market as SASB qualified product.",
      },
      {
        date: "2020",
        copy:
          "The S Factor Co. becomes a member of the Canadian Association of Alternative Strategies & Assets (CAASA).",
      },
      {
        date: "2020",
        copy:
          "S-Factor CEO records her first TED talk on The Social Impact of Math, introducing the S-Factor’s Theory of Change. That is value creation for the performance of the social factors™.",
        extraSpace: true,
      },
    ],
  }

  renderItems(items) {
    return items.map((item, index) => {
      return (
        <div
          className={
            item.extraSpace
              ? "timeline-item timeline-item--extra-space"
              : "timeline-item"
          }
          key={index}
        >
          <div className="circle"></div>
          <h5>{item.date}</h5>
          <p>{item.copy}</p>
        </div>
      )
    })
  }
  render() {
    const leftItems = this.state.items.filter((element, index, array) => {
      return index % 2 !== 0
    })

    const rightItems = this.state.items.filter((element, index, array) => {
      return index % 2 === 0
    })

    console.log("left", leftItems)
    console.log("right", rightItems)

    return (
      <div className="timeline-diagram">
        <div className="timeline-divider"></div>
        <div className="timeline-left">{this.renderItems(leftItems)}</div>
        <div className="timeline-right">{this.renderItems(rightItems)}</div>
        <div className="clear"></div>
      </div>
    )
  }
}

export { TimelineDiagram }
