import React, { Component } from "react"
import Modal from "react-modal"
import Layout from "../components/layout"
import SEO from "../components/seo"

import TopContainer from "../components/TopContainer"

import { Group, Col, Section, CtaButton } from "../components/common"

import img from "../images/stock/about1.jpg"
import logoMark from "../images/logoMark.png"

import { team } from "../data"
import { TimelineDiagram } from "../components/diagrams/TimelineDiagram"

import company1 from "../images/testimonials/quote-logo01.png"
import company2 from "../images/testimonials/quote-logo02.png"
import company3 from "../images/testimonials/quote-logo03.png"

import partner1 from "../images/partners/partner1.png"
import partner2 from "../images/partners/partner2.png"
import partner3 from "../images/partners/partner3.png"
import partner4 from "../images/partners/partner4.png"
import partner5 from "../images/partners/partner5.png"
import partner6 from "../images/partners/partner6.png"

class AboutPage extends Component {
  state = {
    modalIsOpen: false,

    team: team,
    currentPerson: null,
    testimonials: [
      {
        text:
          "It is the most comprehensive and systematic rating system the sector has ever seen.",
        name: "Sir Mark Moody-Stuart",
        position: "Chairman UN Global Compact (SDGs), Ex-Chairman Shell",
        imageSrc: company1,
      },
      {
        text:
          "They have effectively taken years of in-depth experience and reams of boots-on-the-ground learning to build data sets and market signals to help the broader regulatory and investment industry leaders assess how companies perform in critical areas of social responsibility.",
        name: "Robert (Bob) Jenkins",
        position: "Global Head of Research, Thomson Reuters",
        imageSrc: company2,
      },
      {
        text:
          "Her work on the MSPI Index was an innovation way ahead of its time, that others since have tried to build upon.",
        name: "Mark Cutifani",
        position: "CEO Anglo American",
        imageSrc: company3,
      },
    ],

    partners: [
      {
        img: partner1,
        url: "https://thegiin.org/",
      },
      {
        img: partner2,
        url: "https://www.sasb.org/",
      },
      {
        img: partner3,
        url: "https://eaglealpha.com/",
      },
      // {
      //   img: partner4,
      //   url: "https://fullyvested.com/",
      // },
      {
        img: partner5,
        url: "https://www.cqam.ca/",
      },
      {
        img: partner6,
        url: "https://caasa.ca/",
      },
    ],
  }

  openModal(p) {
    this.setState({ currentPerson: p, modalIsOpen: true })
  }

  afterOpenModal() {}

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  renderPerson(p, index) {
    return (
      <div className="person" key={index}>
        <div
          className="image"
          style={{ backgroundImage: `url(${p.image})` }}
          onClick={this.openModal.bind(this, p)}
        />
        <div className="name">{p.name}</div>
        {p.position ? <div className="position">{p.position}</div> : null}
        {/* <div className="bio">{p.bioShort}</div> */}
        {/* <div className="link">
          <a href="javascript: void" onClick={this.openModal.bind(this, p)}>
            Read More
          </a>
        </div> */}
      </div>
    )
  }

  renderPeople(people) {
    return people.map((p, index) => {
      return this.renderPerson(p, index)
    })
  }

  renderTestimonials() {
    return this.state.testimonials.map((t, index) => {
      return (
        <div className="testimonial" key={index}>
          <div className="box">
            <p>"{t.text}"</p>

            <div className="bottom">
              <div
                className="companyLogo"
                style={{ backgroundImage: `url(${t.imageSrc})` }}
              />
              <div className="cite">
                <div className="name">{t.name}</div>
                <div className="position">{t.position}</div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }
  renderPartners() {
    return this.state.partners.map((a, i) => {
      return (
        <a href={a.url} className="award" target="_blank">
          <img src={a.img} />
        </a>
      )
    })
  }

  renderModalContent() {
    if (!this.state.currentPerson) {
      return
    }

    return (
      <Group>
        <Col size="third">
          <div className="name">
            {this.state.currentPerson.name}
            {this.state.currentPerson.suffix
              ? `, ${this.state.currentPerson.suffix}`
              : null}
          </div>

          <div
            className="image"
            style={{
              backgroundImage: `url(${this.state.currentPerson.image})`,
            }}
          />
        </Col>
        <Col size="two-thirds">
          <div className="position">{this.state.currentPerson.position}</div>
          <div className="bio">
            <p style={{ fontWeight: "bold" }}>
              {this.state.currentPerson.bioShort}
            </p>
            {this.state.currentPerson.bioLong.map((paragraph, index) => {
              return <p key={index}>{paragraph}</p>
            })}
          </div>
          <ul class="links">
            {this.state.currentPerson.links.map(link => {
              return (
                <li>
                  <a href={link.url} target="_blank">
                    <i class="fa fa-linkedin-square"></i>
                  </a>
                </li>
              )
            })}
          </ul>
        </Col>
      </Group>
    )
  }

  render() {
    return (
      <>
        <Layout>
          <SEO
            title="Financial Performance | Systematic Rating System"
            keywords={["Financial Performance", "Systematic Rating System"]}
            description="We provide social impact information that measures companies financial performance with a systematic rating system. Reach out to us to learn more."
          />
          <TopContainer
            titleLines={[
              "The S-Factor provides the deepest set of social impact data currently available on the market, and delivers it in a platform that is completely customizable to suit client’s needs.",
            ]}
            backgroundImage={img}
            body="As subject matter experts and veterans in measuring social impact around the world, we help the SRI/ESG, alternative data market, asset managers, and investors make more informed and impactful investment decisions."
            // ctaText="Check out our latest report"
            // ctaLinkTo={"/about"}
          />
          <Section hasDivider={false}>
            <Group>
              <div className="headingAccent" />
              <h3>Who we are</h3>
            </Group>
          </Section>

          <Section hasDivider={false}>
            <Group>
              <Col size="third">
                <div className="line"></div>
                <h4 className="accent">Board of Directors</h4>
              </Col>
              <Col size="two-thirds" />
            </Group>
            <div className="people people--emphasize">
              {this.renderPeople(this.state.team.directors)}
            </div>
            <div className="clear" />
          </Section>

          <Section hasDivider={false}>
            <Group>
              <Col size="third">
                <h4 className="accent">Audit Committee</h4>
              </Col>
              <Col size="two-thirds" />
            </Group>
            <div className="people people--emphasize">
              {this.renderPeople(this.state.team.auditCommittee)}
            </div>
            <div className="clear" />
          </Section>

          <Section hasDivider={false}>
            <Group>
              <Col size="third">
                <h4 className="accent">Advisors</h4>
              </Col>
              <Col size="two-thirds" />
            </Group>
            <div className="people">
              {this.renderPeople(this.state.team.advisory)}
            </div>

            <div className="clear" />
          </Section>

          <Section hasDivider={false}>
            <Group>
              <Col size="third">
                <h4 className="accent">Senior Management</h4>
              </Col>
              <Col size="two-thirds" />
            </Group>
            <div className="people">
              {this.renderPeople(this.state.team.management)}
            </div>
            <div className="clear" />
          </Section>

          <Section>
            <Group>
              <Col size="third">
                <h4 className="accent">Team</h4>
              </Col>
              <Col size="two-thirds" />
            </Group>
            <div className="people">
              {this.renderPeople(this.state.team.team)}
            </div>

            <div className="clear" />
          </Section>

          <Section>
            <Group id="testimonials">
              <Col size="third">
                <div className="headingAccent" />

                <h3>Testimonials</h3>
              </Col>
              <Col size="two-thirds" />
            </Group>

            {this.renderTestimonials()}
            <div className="clear" />
          </Section>

          <Section>
            <Group>
              <Col size="third">
                <div className="headingAccent" />

                <h3>Partners</h3>
              </Col>
              <Col size="two-thirds" />
            </Group>

            <div className="awards">{this.renderPartners()}</div>
            <div className="clear" />
          </Section>

          <Section>
            <Group>
              <div className="headingAccent" />
              <h3>History</h3>

              <TimelineDiagram />
            </Group>
          </Section>

          <Section hasDivider={false}>
            <Group>
              <Col size="half">
                <div className="headingAccent" />

                <h3>What We Do</h3>
              </Col>
              <Col size="half">
                <p className="medium">
                  The S-Factor provides social impact information, company
                  ratings and rankings, and an index product which measure
                  companies’ financial performance in relation to their social
                  impact.
                </p>

                <CtaButton linkTo={"s-factor"} dark={true}>
                  Learn More
                </CtaButton>
              </Col>
            </Group>
          </Section>

          <div style={{ height: 32 }} />

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal.bind(this)}
            style={modalStyle}
            contentLabel="Example Modal"
            closeTimeoutMS={500}
          >
            <div className="modalContent">
              <div className="modalClose" onClick={this.closeModal.bind(this)}>
                Close
              </div>
              {this.renderModalContent()}
            </div>
          </Modal>
        </Layout>
      </>
    )
  }
}

const modalStyle = {
  overlay: {
    zIndex: 500,
    maxWidth: 926,
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    marginLeft: -6,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "100%",
    // marginRight: '-50%',
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    maxHeight: "100vh",
  },
}

export default AboutPage
